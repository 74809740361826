import { createStore } from "vuex" 

import router from "@/router";
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

const store = createStore({
   state: {
        user: null,
        loading: false,
        error: null,
   },
   getters: {
      authUser: (state) => {
        return state.user;
      },
      isAdmin: (state) => {
        return state.user.isAdmin;
      },
      error: (state) => {
        return state.error;
      },
      loading: (state) => {
        return state.loading;
      },
      loggedIn: (state) => {
        return !!state.user;
      },
    },
    mutations: {
      SET_USER(state, user) {
        state.user = user;
      },
      SET_LOADING(state, loading) {
        state.loading = loading;
      },
      SET_MESSAGE(state, message) {
        state.message = message;
      },
      SET_ERROR(state, error) {
        state.error = error;
      },
    },
    actions: {
      logout({ commit }) {
        return AuthService.logout()
          .then(() => {
            commit("SET_USER", null);
            router.push({ path: "/login" });
          })
          .catch((error) => {
            commit("SET_ERROR", getError(error));
          });
      },
      getAuthUser({ commit }) {
        commit("SET_LOADING", true);
        return AuthService.getAuthUser()
          .then((response) => {
            commit("SET_USER", response.data.id);
            commit("SET_LOADING", false);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_USER", null);
            commit("SET_ERROR", getError(error));
          });
      },
    }
})

export default store