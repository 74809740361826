import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      metaTitle: 'Welcome',
     // pageTitle:'Welcome' ,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      metaTitle: 'TBFF',
     // pageTitle: 'Login',
      noNav: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      requiresAuth: false,
      metaTitle: 'Register',
     // pageTitle: 'Register',
      noNav: true,
    },
    component: function () {
      return import(/* webpackChunkName: "register" */ '../views/Register.vue')
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgot_password',
    meta: {
      requiresAuth: false,
      metaTitle: 'Forgot Password',
     // pageTitle: 'Register',
      noNav: true,
    },
    component: function () {
      return import(/* webpackChunkName: "forgotpassword" */ '../views/ForgotPassword.vue')
    }
  },
  {
    path: '/resetpassword',
    name: 'reset_password',
    meta: {
      requiresAuth: false,
      metaTitle: 'Reset Password',
     // pageTitle: 'Register',
      noNav: true,
    },
    component: function () {
      return import(/* webpackChunkName: "forgotpassword" */ '../views/ResetPassword.vue')
    }
  },
  {
    path: '/study',
    name: 'study',
    component: Home,
    meta: {
      requiresAuth: true,
      metaTitle: 'Study',
     // pageTitle:'Study' ,
    },
  },
  {
    path: '/daytime',
    name: 'daytime',
    meta: {
      requiresAuth: true,
      metaTitle: 'E-Learning',
    //  pageTitle: 'E-Learning',
    },
    component: function () {
      return import('../views/ShowList.vue')
    }
  },
  {
    path: '/daytime/:name',
    name: 'daytime_item',
    meta: {
      requiresAuth: true,
    //  pageTitle: 'Daytime',
      backNav: true,
      pageRoot: '/daytime',
    },
    component: function () {
      return import('../views/Show.vue')
    }
  },
  {
    path: '/elearn',
    name: 'elearn',
    meta: {
      requiresAuth: true,
      metaTitle: 'E-Learning',
    //  pageTitle: 'E-Learning',
    },
    component: function () {
      return import('../views/ElearnList.vue')
    }
  },
  {
    path: '/elearn/:name',
    name: 'elearn_item',
    meta: {
      requiresAuth: true,
    //  pageTitle: 'ELearn',
      backNav: true,
      pageRoot: '/elearn',
    },
    component: function () {
      return import('../views/Show.vue')
    }
  },
  {
    path: '/evening',
    name: 'evening',
    meta: {
      requiresAuth: true,
      metaTitle: 'Evening',
    //  pageTitle: 'Evening',
    },
    component: function () {
      return import('../views/ShowListAlt.vue')
    }
  },
  {
    path: '/evening/:name',
    name: 'evening_item',
    meta: {
      requiresAuth: true,
    //  pageTitle: 'Evening',
      backNav: true,
      pageRoot: '/evening',
    },
    component: function () {
      return import('../views/Show.vue')
    }
  },
  {
    path: '/production',
    name: 'production',
    meta: {
      requiresAuth: true,
      metaTitle: 'Production Shows',
     // pageTitle: 'Production Shows',
    },
    component: function () {
      return import('../views/ShowList.vue')
    }
  },
  {
    path: '/production/:name',
    name: 'production_item',
    meta: {
      requiresAuth: true,
      backNav: true,
      pageRoot: '/production',
    },
    component: function () {
      return import('../views/Show.vue')
    }
  },
  {
    path: '/dances',
    name: 'dances',
    meta: {
      requiresAuth: true,
      metaTitle: 'Dances',
   //   pageTitle: 'Dances',
    },
    component: function () {
      return import(/* webpackChunkName: "support" */ '../views/Support.vue')
    }
  },

  { 
    path: '/support',
    name: 'support',
    meta: {
      requiresAuth: true,
      metaTitle: 'Support',
     // pageTitle: 'Support',
    },
    component: function () {
      return import(/* webpackChunkName: "support" */ '../views/Support.vue')
    }
  },

  {
    path: '/video/:folder/:name',
    meta: {
      requiresAuth: true,
      backNav: true,
      metaTitle: 'Video',
    //  pageTitle: 'Video',
      popup: true,
    },
    component: function () {
      return import('../views/Video.vue')
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      requiresAuth: false,
      metaTitle: 'Logout',
   //   pageTitle: 'Logout',
    },
    component: function () {
      store.dispatch("logout");
    }
  },
  // IF NOT ROUTES MATCHED DISPLAY 404 PAGE
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: {
      requiresAuth: false,
      metaTitle: 'Page Not Found',
      noNav: true,
    },
    component: function () {
      return import(/* webpackChunkName: "404" */ '../views/404.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Before Route Runs
router.beforeEach((to, from, next) => {

  // Updates HTML Page Meta Title when link passed with metaTitle param
  if (to.params.metaTitle) {
    document.title = to.params.metaTitle;
  } else if (to.meta.metaTitle) {
    document.title = to.meta.metaTitle;
  } else {
    document.title = 'TBFF';
  }

  //document.title = to.params.metaTitle ? to.params.metaTitle : 'TBFF';

  // Auth Check
  const authUser = store.getters["authUser"];
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  if (reqAuth && !authUser) {
    store.dispatch("getAuthUser").then(() => {
      if (!store.getters["authUser"]) next(loginQuery);
      else next();
    });
  } else {
    next();
  }
});

export default router

