<template>
<div class="">





  <div class="h-screen flex items-center justify-center relative lg:-mt-10 lg:-top-0 -top-10 px-4 sm:px-6 lg:px-8 ">
  <div class="max-w-md lg:max-w-sm w-full space-y-8">
    <div>
      <img class="mx-auto h-auto w-3/4 pb-3" src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/lbsqtrans.png">
    <!--  <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Live Business Training
      </h2> -->
    </div>
    <form class="mt-8 space-y-6" ref="form" @submit.prevent="login">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
        </div>
      </div>

      <div class="flex items-center justify-center ">
          <div class="text-sm">
          <a href="/forgotpassword" class="font-medium text-gray-400 hover:text-gray-600">
            Forgot your password?
          </a>
        </div>
      </div>

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lborange hover:bg-lbg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lbf">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="h-5 w-5 text-white group-hover:text-lborange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>

<FlashMessage :error="error" @close="error = null" />
</div>
</template>

<script lang="ts">
//import store from '@/store'
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";

export default {
    name: 'login',
    data () {
    return {
          email: '',
          password: '',
          error: null,
  }
},
components: {
    FlashMessage,
},
  methods: {
      login() {
        const payload = {
          email: this.email,
          password: this.password,
        };
        AuthService.login(payload)
          .then(() => this.$router.push("/"))
          .catch((error) => (this.error = getError(error)));
      },
  },
  mounted () {

  }
}
</script>