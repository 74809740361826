<template>

<div id="nav" class="" v-if="$route.meta.noNav !== true">
  <div id="header" class="bg-lborange fixed z-30 left-0 top-0 w-full shadow-lg px-5" :class="{ 'pt-8': isActive }">

    <div class="grid grid-cols-3 justify-items-center max-w-4xl mx-auto py-5">

      
      <div class="justify-self-start h-6">
          <div @click="openSidebarPanel" class="" v-if="$route.meta.backNav !== true" style="cursor: pointer;">
            <svg class="text-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
          <div @click="$route.meta.popup !== true ? $router.push($route.meta.pageRoot) : $router.go(-1)" class="" v-if="$route.meta.backNav === true" style="cursor: pointer;">
              <div class="inline-flex text-white items-center">
                <svg class="text-white h-5 w-5 -ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                Go Back
              </div>
          </div>
      </div>

      <div class="justify-self-center font-bold text-center w-auto">
        <transition name="title-fade" mode="out-in">
          <div class="whitespace-nowrap">
            {{ $route.meta.pageTitle }}
          </div>
        </transition>
      </div>

<!-- LOGO -->

      <div class="justify-self-end h-8 w-8">

<a href="/"><img src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/whitelb.png" alt="Home"></a>


      </div>
    </div>
  </div>
</div>

    <div class="sidebar">
        <div class="sidebar-backdrop z-40" @click="closeSidebarPanel" :class="{ 'active': isPanelOpen }"></div>
            <div :class="{ 'active': isPanelOpen }" class="sidebar-panel z-50">
                     <div class="justify-left">
                     <img src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/navlbwhite.png">
                    </div>

              <div class="py-5 text-2xl ">
              </div>
              <div class="divide-y divide-white">
                <router-link class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" to="/"><b>&nbsp;&nbsp;Home</b></router-link>
              <router-link class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" to="/elearn"><b>&nbsp;&nbsp;E-Learning</b></router-link>
             <router-link class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" to="/evening"><b>&nbsp;&nbsp;Light Entertainment</b></router-link>
                <router-link class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" to="/production"><b>&nbsp;&nbsp;Production Shows</b></router-link>
                <router-link class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" to="/dances"><b>&nbsp;&nbsp;Party Dances</b></router-link>
               <!-- <div class="block py-2 text-white text-lg tracking-wider hover:bg-white hover:text-lborange" ><a href="javascript:void(Tawk_API.toggle())"> <b>&nbsp;&nbsp;Support </b></a></div> -->
              </div>

              <!-- Weekly Report Link -->
                         
<div class="py-5 text-xl">
<button class="bg-white hover:bg-gray-400 text-lborange font-bold py-2 px-4 rounded-full">
  <a href="https://forms.gle/ZniAVta1wN9EP8dh9">TL Weekly Report</a>
</button>
</div>
 
<div class="py-2 text-xl">
<button class="bg-white hover:bg-gray-400 text-lborange font-bold py-2 px-4 rounded-full">
  <a href="https://forms.gle/RSReLdEoX1Pm5PWH7">Incident Report</a>
</button>
</div>



              <!--CHANGE VERSION NUMBER HERE -->
                 <div class=" absolute bottom-2 left-4 p-4 text-gray-600 text-sm">Version 1.3.6
                 </div>
                
            </div>
    </div>

<div class="window max-w-4xl mx-auto px-0 z-0" :class="{ 'mt-8': isActive && $route.meta.noNav !== true, 'pt-24 lg:pt-24': $route.meta.noNav !== true}">

  <!--
  <transition name="fade">
    <router-view/>
  </transition>
  -->

<router-view v-slot="{ Component }">
  <transition name="fade" mode="out-in">
    <component :is="Component" :key="$route.fullPath" />
  </transition>
</router-view>

  <loading-modal v-if="loading_modal"></loading-modal>
</div>

  
</template>

<script>
import Loading from './components/loading.vue';
//import store from './store';

// Detect for iPhone
var ios = false;
if(/iphone/i.test(navigator.userAgent)) {
  if (window.navigator.standalone) {
    ios = true;
  }
}

export default {
  name: 'app',
  components: {
    'loading-modal': Loading,
  },
  watch: {
  '$route' () {
      this.closeSidebarPanel();
    }
  },
  data() {
    return {
      isActive: ios,
      isPanelOpen: false,
      loading_modal: false,
    }
  },
  methods: {
    closeSidebarPanel() {
        this.isPanelOpen = false
    },
    openSidebarPanel() {
        this.isPanelOpen = true
    },
    toggleSidebarPanel() {
      if (this.isPanelOpen) {
        this.closeSidebarPanel();
      } else {
        this.openSidebarPanel();
      }
    }
  },
  mounted () {
    //screen.orientation.lock('portrait');
  },
  beforeMount ()  {

  }
  
}

</script>

<style>
    .sidebar-backdrop {
        background-color: rgba(0, 0, 0, .4);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        cursor: pointer;
        display: none;
        transition: all 300ms ease-in-out;
    }
    .sidebar-panel {
        overflow-y: auto;
        background-color: #ef7e00;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 999;
        padding: 3rem 20px 2rem 20px;
        width: 300px;
        left: -300px;
        transition: all 300ms ease-in-out;
    }
    .sidebar-backdrop.active {
      display: block;
    }
    .sidebar-panel.active {
      transform: translateX(100%);
    }
    
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .title-fade-enter-active,
    .title-fade-leave-active {
      transition: opacity 0.1s ease;
    }

    .title-fade-enter-from,
    .title-fade-leave-to {
      opacity: 0;
    }

</style>